
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "es"
]

export const localeLoaders = {
  de: [],
  en: [],
  es: []
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/63ee0c03" /* webpackChunkName: "config_i18n_46config_46ts_63ee0c03" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: false
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "yaml",
    globalSFCScope: false
  },
  locales: [
    {
      code: "de",
      language: "de-DE",
      name: "Deutsch",
      files: []
    },
    {
      code: "en",
      language: "en-US",
      name: "English",
      files: []
    },
    {
      code: "es",
      language: "es-ES",
      name: "Español",
      files: []
    }
  ],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.kinoheld.de",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "de",
    language: "de-DE",
    name: "Deutsch",
    files: []
  },
  {
    code: "en",
    language: "en-US",
    name: "English",
    files: []
  },
  {
    code: "es",
    language: "es-ES",
    name: "Español",
    files: []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/